import { types, Instance } from 'mobx-state-tree';
import { AdjustmentType } from './AdjustmentType';
import { TimeSheetHours } from '../TimeSheetHours';
import { TimeSheetStatus } from 'components/TimeSheetStatusViewer';
import { DATE_FORMAT, getDateTime } from '@utils';
import { differenceInMinutes, format, isValid } from 'date-fns';
import { toTwoDecimalNumber } from '@utils/decimal';
import { LeaveType } from './LeaveType';

const isLeaveTypeRequired = (type: AdjustmentType) => {
  return type === AdjustmentType.Leave 
};

export const PayrollAdjustment = types.model({
  id: types.optional(types.string, ''),
  status: types.enumeration('TimeSheetStatus', [
              TimeSheetStatus.Approved,
              TimeSheetStatus.New,
              TimeSheetStatus.Processed,
              TimeSheetStatus.Rejected,
              TimeSheetStatus.Submitted,
              TimeSheetStatus.Integrated,
              TimeSheetStatus.NoData,
              TimeSheetStatus.Draft,
              TimeSheetStatus.Final
            ]),
  type: types.enumeration('AdjustmentType', [
    AdjustmentType.Time,
    AdjustmentType.Leave,
    AdjustmentType.OnCallAllowance,
    AdjustmentType.PHOnCallAllowance,
    AdjustmentType.MealAllowance
  ]),
  leaveType:types.enumeration<LeaveType>('LeaveType', Object.values(LeaveType)),
  amount: types.optional(types.number, 0),
  startTime: types.string,
  endTime: types.string,
  reason: types.optional(types.string, ''),
  hours: TimeSheetHours,
  callOut: types.optional(types.boolean, false),
  negative: types.optional(types.boolean, false),
  startTimeError: types.optional(types.string, ''),
  endTimeError: types.optional(types.string, '')
})
.actions((self) => {
  const recalculateTotals = () => {
    const start = getDateTime(self.startTime);
    const end = getDateTime(self.endTime);
    if (start > end) {
      return;
    }

    const totalDifference = differenceInMinutes(end, start)

    const totalHours = toTwoDecimalNumber(totalDifference/60);

    const totalPayHours = toTwoDecimalNumber(totalHours);
    
    if (self.callOut) {
      self.hours.setNormalPay(totalPayHours > 4 ? totalPayHours : 4);
      self.hours.setTotalPay(totalPayHours > 4 ? totalPayHours : 4);

      if(self.negative){
        self.hours.setNormalPay(-self.hours.normalPay);
        self.hours.setTotalPay(-self.hours.totalPay);
      }
      return;
    }

    if(self.negative){
      self.hours.setNormalPay(-totalPayHours);
      self.hours.setTotalPay(-totalPayHours);
    }
    else{
      self.hours.setNormalPay(totalPayHours);
      self.hours.setTotalPay(totalPayHours);
    }
  };

  return {
    setType: (value: AdjustmentType) => {
      self.type = value;
      console.log("Selected adjustment type:", value);

      if(value === AdjustmentType.Leave)
        self.leaveType = LeaveType.AnnualLeave
      else
        self.leaveType = LeaveType.Empty;
      
      if (self.type === AdjustmentType.Time || self.type === AdjustmentType.Leave) {
          self.amount = 0;
      } else {
        self.amount = -1;
      }
    },
    setLeaveType: (value: LeaveType) => {
      console.log("Selected leave type:", value);
      self.leaveType = value;
    },
    setAmount: (value: number) => {
      self.amount = value;
    },
    setReason: (value: string) => {
      self.reason = value;
    },
    setNormalPay: (hours: number) => {
      self.hours.setNormalPay(hours);
      recalculateTotals();
    },
    setOvertime: (hours: number) => {
      self.hours.setOvertime(hours);
      recalculateTotals();
    },
    setDoubleTime: (hours: number) => {
      self.hours.setDoubleTime(hours);
      recalculateTotals();
    },
    setOvertimeDoubleTime: (hours: number) => {
      self.hours.setOvertimeDoubleTime(hours);
      recalculateTotals();
    },
    setStartTime: (startTime: Date) => {
      if(isValid(startTime))
      {
        if(startTime.getMinutes() % 3 == 0)
        {
          self.startTimeError = '';
          self.startTime = format(startTime, DATE_FORMAT);
          self.endTime = format(new Date(self.endTime).setDate(startTime.getDate()), DATE_FORMAT)

          if(startTime > getDateTime(self.endTime))
            self.endTime = format(new Date(self.endTime).setDate(getDateTime(self.endTime).getDate() + 1), DATE_FORMAT)

          recalculateTotals();
        }
        else
        {
          self.startTimeError = 'Time must be entered to the nearest 3 minutes'
        }
      }
      else
      {
        self.startTimeError = 'Time entered is invalid';
      }
    },
    setEndTime: (endTime: Date) => {
      if(isValid(endTime))
      {
        if(endTime.getMinutes() % 3 == 0)
        {
          self.endTimeError = '';
          self.endTime = format(endTime.setDate(new Date(self.startTime).getDate()), DATE_FORMAT)

          if(getDateTime(self.startTime) > endTime)
            self.endTime = format(new Date(endTime).setDate(endTime.getDate() + 1), DATE_FORMAT)

          recalculateTotals();
        }
        else
        {
          self.endTimeError = 'Time must be entered to the nearest 3 minutes'
        }
      }
      else
      {
        self.endTimeError = 'Time entered is invalid';
      }
    },
    setCallOut: (value: boolean) => {
      self.callOut = value;
      recalculateTotals();
    },
    setNegative: (value: boolean) => {
      self.negative = value;
      recalculateTotals();
    },
    recalculateTotals
  };
})
.views((self) => {
  
  const isValidAdjustmentReason = () => {
    return (self.status == TimeSheetStatus.New || self.status == TimeSheetStatus.Rejected) ? self.reason != "" : true
  };
  
  const isValidTimeRange = () => {
    if(getDateTime(self.startTime).getDate() != getDateTime(self.endTime).getDate())
      return getDateTime(self.startTime) < getDateTime(self.endTime) && self.callOut
    else
      return getDateTime(self.startTime) < getDateTime(self.endTime)
  };

  const isValidLeaveType = () => {
    return self.type === AdjustmentType.Leave ? self.leaveType != 'Empty' : true
  };

  return {
    get isAllowanceType() {
      return self.type === AdjustmentType.OnCallAllowance ||
        self.type === AdjustmentType.PHOnCallAllowance ||
        self.type === AdjustmentType.MealAllowance;
    },
    get isLeaveRequired() {
      return isLeaveTypeRequired(self.type);
    },
    get isReadOnly() {
      return self.status === TimeSheetStatus.Submitted || self.status === TimeSheetStatus.Approved ||
      self.status === TimeSheetStatus.Processed;
    },
    get canDelete() {
      return self.status === TimeSheetStatus.New ||
        self.status === TimeSheetStatus.Rejected;
    },
    get isAdjustmentReasonValid() {
      return isValidAdjustmentReason();
    },
    get isValidTimeRange() {
      return isValidTimeRange();
    },
    get isValidLeaveType() {
      return isValidLeaveType();
    },
    hasOverlap(adjustment: Instance<typeof PayrollAdjustment>) {
      return getDateTime(self.startTime) < getDateTime(adjustment.endTime)
        && getDateTime(self.endTime) > getDateTime(adjustment.startTime);
    },
    validate() {
      return {
        adjustmentReasonMissingError: !isValidAdjustmentReason()
          ? 'Reason is required'
          : '',
        startTimeError: self.startTimeError,
        endTimeError: self.endTimeError,
        timeRangeError:!isValidTimeRange() ? 'Only call out timesheet can go over midnight' : '',
        leaveTypeMissingError: !isValidLeaveType() ? 'Leave must have selected leave type' : ''
      };
    },
    equals(adjustment) {
      return self.id === adjustment.id
      && self.status === adjustment.status
      && self.type === adjustment.type
      && self.leaveType === adjustment.leaveType
      && self.amount === adjustment.amount
      && format(getDateTime(self.startTime), DATE_FORMAT) === format(getDateTime(adjustment.startTime), DATE_FORMAT)
      && format(getDateTime(self.endTime), DATE_FORMAT) === format(getDateTime(adjustment.endTime), DATE_FORMAT)
      && self.reason === adjustment.reason
      && self.hours.equals(adjustment.hours);
    },
    get startDateTime() {
      return getDateTime(self.startTime);
    },
    get endDateTime() {
      return getDateTime(self.endTime);
    }
  }
});

export type PayrollAdjustmentModel = Instance<typeof PayrollAdjustment>;
